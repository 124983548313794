import React, { forwardRef } from 'react';

import { cn } from '@/lib/utils';

const GAPS = {
  0: 'gap-0',
  1: 'gap-1',
  2: 'gap-2',
  3: 'gap-3',
  4: 'gap-4',
  5: 'gap-5',
  6: 'gap-6',
};

interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  horizontal?: boolean;
  gap?: keyof typeof GAPS;
  wrap?: boolean;
  children: React.ReactNode;
}

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ horizontal = false, className, gap, wrap, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex', gap && GAPS[gap], className, {
          'flex-col': !horizontal,
          'flex-row items-center': horizontal,
          'flex-wrap': wrap,
        })}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Stack.displayName = 'Stack';
